import { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'scss/manifest.scss';
import useConfig from 'mfe-core/hooks/useConfig';
import Head from 'next/head';
import GPTLoader from 'mfe-core/ui/GPTLoader';
import getDeviceType from 'mfe-articles-shared/utilities/getDeviceType/getDeviceType';
import MobileAdLoader from 'components/MobileAdLoader/MobileAdLoader';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';
import useUserInit from 'mfe-core/hooks/useUserInit';
import usePublicEnvVarsStore from 'mfe-articles-shared/stores/publicEnvVars/publicEnvVars.store';
import useFeatureFlags from 'mfe-core/hooks/useFeatureFlags';

import SkipLink from 'components/SkipLink/SkipLink';
import NewRelicBrowserAgent from 'components/NewRelicBrowserAgent/NewRelicBrowserAgent';
import adUnits from 'utilities/adUnits/adUnits';
import {
    isGovermentVisitor,
    emailIsF100Domain,
} from 'utilities/pageTargets/pageTargets';
import useRecaptcha from 'mfe-core/hooks/useRecaptcha';

function App({ Component, pageProps }) {
    const pageTargets = pageProps.article
        ? {
              category: pageProps.article.topics?.[0] ?? '',
              iid: `tag:${pageProps.article.tag}`,
              pageType: 'ITEM',
              oxGroup: '537063787',
              articleType: pageProps.article?.issue
                  ? 'Magazine Article'
                  : 'Digital Article',
              title: pageProps.article.title,
              pid: pageProps.article.metaTags?.['page-id'],
              Government_visitors: isGovermentVisitor(
                  pageProps.profile?.emailAddress,
              ),
              F100_visitors: emailIsF100Domain(pageProps.profile?.emailAddress),
          }
        : {};

    useRecaptcha();

    const { setApiOrigin } = useConfig();
    setApiOrigin(pageProps.apiOrigin);

    const setEnvVar = usePublicEnvVarsStore((state) => state.setEnvVar);
    setEnvVar('apiOrigin', pageProps.apiOrigin);
    setEnvVar('storeDomain', pageProps.storeDomain);

    useUserInit();

    const setPianoParams = useArticleStore((state) => state.setPianoParams);
    setPianoParams(pageProps.pianoParams);

    const setArticleStore = useArticleStore((state) => state.setArticle);
    setArticleStore(pageProps.article);

    const { setFlags } = useFeatureFlags();
    setFlags(pageProps.featureFlags);

    const setConfigParams = usePaywallStore((state) => state.setConfigParams);
    setConfigParams(pageProps.pianoParams);

    const setTriggerPianoComposerCall = useArticleStore(
        (state) => state.setTriggerPianoComposerCall,
    );
    setTriggerPianoComposerCall(true);

    useEffect(() => {
        const deviceType = getDeviceType(window.navigator.userAgent, {
            maxTouchPoints: window.navigator.maxTouchPoints,
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            ontouchstart: window.ontouchstart,
        });

        window.googletag = window.googletag || { cmd: [] };

        window.googletag.cmd.push(function () {
            window.googletag.pubads().setTargeting('layout', deviceType);
            window.googletag.enableServices();
        });
    }, []);

    return (
        <>
            <Head>
                <link rel="dns-prefetch" href="//js-agent.newrelic.com/" />
                <link rel="dns-prefetch" href="//assets.adobedtm.com" />
                <link
                    rel="dns-prefetch"
                    href={process.env.API_ORIGIN?.replace('https:', '')}
                />
                <link
                    rel="dns-prefetch"
                    href="//securepubads.g.doubleclick.net"
                />
                <link rel="dns-prefetch" href={process.env.PIANO_BASE_URL} />
                <link rel="dns-prefetch" href="//connect.facebook.net" />
                <link rel="dns-prefetch" href="//geoip-js.com" />
                <meta property="og:type" content="article" />
                <NewRelicBrowserAgent />
                <script src="//assets.adobedtm.com/c0857d0b892a/7e3e4b83f0c5/launch-67dbffcc16ef.min.js" />
                <script
                    async
                    src="https://cdnapisec.kaltura.com/p/506471/embedPlaykitJs/uiconf_id/55694072"
                />
                <MobileAdLoader />
                <GPTLoader
                    adUnits={adUnits}
                    pageTargets={pageTargets}
                    userProfile={pageProps.profile}
                />
            </Head>
            <SkipLink />
            {
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Component {...pageProps} />
            }
        </>
    );
}

App.propTypes = {
    Component: PropTypes.element.isRequired,
    pageProps: PropTypes.shape({
        article: PropTypes.instanceOf(Object).isRequired,
        profile: PropTypes.instanceOf(Object).isRequired,
        apiOrigin: PropTypes.string.isRequired,
        storeDomain: PropTypes.string.isRequired,
        pianoParams: PropTypes.instanceOf(Object).isRequired,
        featureFlags: PropTypes.instanceOf(Object).isRequired,
    }).isRequired,
};

export default App;
